<template>
  <el-dialog :title="$t('device.intercom')" :close-on-click-modal="false" :visible.sync="dialogVisible" width="1000px"
    top="5vh" @close="onDialogClose">
    <div v-loading="contentLoading" :element-loading-text="contentLoadingTxt" class="body">
      <el-form :inline="true" label-width="100px">
        <el-row :gutter="24">
          <el-col :span="9">
            <el-form-item :label="$t('device.softwareVersion')">
              <el-input v-model="info.softwareVersion" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item :label="$t('device.hardwareVersion')">
              <el-input v-model="info.hardwareVersion" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button type="warning" :loading="btnLoading" @click="handleSetMG()">
                {{ $t("event.reset") }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" v-if="userName === 'admin' || userName === 'superAdmin'">
          <el-col :span="9">
            <el-form-item :label="$t('device.serverAddress')">
              <el-input v-model="info.serverAddress" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item :label="$t('device.port')">
              <el-input v-model="info.port" :placeholder="$t('common.pleaseEnter')"
                oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button type="primary" :loading="submitLoading" @click="handleRead(0)">
                {{ $t("device.read") }}
              </el-button>
              <el-button type="primary" style="margin-left: 12px;" :loading="submitLoading" @click="handleSet(0)">
                {{ $t("device.setUp") }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" v-if="userName === 'admin' || userName === 'superAdmin'">
          <el-col :span="9">
            <el-form-item :label="$t('device.account')">
              <el-input v-model="info.account" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item :label="$t('device.password')">
              <el-input v-model="info.password" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button type="primary" :loading="submitLoading" @click="handleRead(1)">
                {{ $t("device.read") }}
              </el-button>
              <el-button type="primary" style="margin-left: 12px;" :loading="submitLoading" @click="handleSet(1)">
                {{ $t("device.setUp") }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="18">
            <el-form-item :label="$t('device.callNos')">
              <el-input style="width: 165px;" v-model="info.callNo1" :placeholder="$t('common.pleaseEnter')"></el-input>
              <el-input style="width: 165px;margin: 0 19px;" v-model="info.callNo2"
                :placeholder="$t('common.pleaseEnter')"></el-input>
              <el-input style="width: 165px;" v-model="info.callNo3" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button type="primary" :loading="submitLoading" @click="handleRead(2)">
                {{ $t("device.read") }}
              </el-button>
              <el-button type="primary" style="margin-left: 12px;" :loading="submitLoading" @click="handleSet(2)">
                {{ $t("device.setUp") }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="info.hardwareVersion === '66.2.0'" :gutter="24">
          <el-col :span="24" style="padding-left: 110px;">
            <el-form-item>
              <el-checkbox-group v-model="info.isDutyRoom">
                <el-checkbox label="dutyRoom">{{ $t("device.dutyRoom") }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="18">
            <el-form-item :label="$t('device.volume')">
              <el-slider style="width: 532px;" v-model="info.volume"></el-slider>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button type="primary" :loading="submitLoading" @click="handleRead(3)">
                {{ $t("device.read") }}
              </el-button>
              <el-button type="primary" style="margin-left: 12px;" :loading="submitLoading" @click="handleSet(3)">
                {{ $t("device.setUp") }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="18">
            <el-form-item :label="$t('device.inFilter')">
              <el-switch v-model="info.inFilter" :active-text="$t('device.inFilterOpen')"
                :inactive-text="$t('device.inFilterClose')">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" :loading="submitLoading" @click="handleRead(4)">
              {{ $t("device.read") }}
            </el-button>
            <el-button type="primary" style="margin-left: 12px;" :loading="submitLoading" @click="handleSet(4)">
              {{ $t("device.setUp") }}
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import auth from "@/util/auth";

export default {
  data() {
    return {
      userName: auth.getUsername(),
      submitLoading: false,
      dialogVisible: false,
      contentLoading: false,
      btnLoading: false,
      contentLoadingTxt: "",
      dtuCode: "",
      nodeCode: "",
      info: {
        dtuCode: "",
        nodeCode: "",
        softwareVersion: "",
        hardwareVersion: "",
        serverAddress: "",
        port: "",
        account: "",
        password: "",
        callNo1: "",
        callNo2: "",
        callNo3: "",
        volume: 0,
        isDutyRoom: false,
        inFilter: false,
      },
      ruleValidate1: {
        serverAddress: [
          { required: true, message: this.$t("device.serverAddress") + this.$t("common.tip.notNull"), trigger: "blur" },
        ],
        port: [
          { required: true, message: this.$t("device.port") + this.$t("common.tip.notNull"), trigger: "blur" },
        ],
        account: [
          { required: true, message: this.$t("device.account") + this.$t("common.tip.notNull"), trigger: "blur" },
        ],
        password: [
          { required: true, message: this.$t("device.password") + this.$t("common.tip.notNull"), trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    open(dtuCode, nodeCode) {
      this.dialogVisible = true;
      this.submitLoading = false;
      this.contentLoading = false;
      this.contentLoadingTxt = "";
      this.dtuCode = dtuCode;
      this.nodeCode = nodeCode;
      this.info.dtuCode = this.dtuCode;
      this.info.nodeCode = this.nodeCode;
      this.getData();
    },
    getData() {
      this.contentLoading = true;
      // this.saveDisabled = true;
      let params = {
        dtuCode: this.info.dtuCode,
      };
      this.$api.getData("devices/deviceIntercom", params).then(res => {
        let data = res.data;
        this.info.softwareVersion = data.softwareVersion;
        this.info.hardwareVersion = data.hardwareVersion;
        this.info.account = data.accountNo;
        this.info.password = data.accountPwd;
        if (data.ipPort !== null) {
          let arr = data.ipPort.split(",");
          if (arr.length > 1) {
            this.info.serverAddress = arr[0];
            this.info.port = arr[1];
          }
        }
        if (data.callNos !== null) {
          let arr = data.callNos.split(",");
          if (arr.length >= 1) {
            this.info.callNo1 = arr[0];
          }
          if (arr.length >= 2) {
            this.info.callNo2 = arr[1];
          }
          if (arr.length >= 3) {
            this.info.callNo3 = arr[2];
          }
        }
        if (data.volume !== null) {
          this.info.volume = data.volume * 1;
        }
        if (data.inFilter !== null) {
          this.info.inFilter = data.inFilter === "1";
        }
        this.info.isDutyRoom = data.dutyRoom;
        this.contentLoading = false;
      }).catch(() => {
        this.contentLoading = false;
      });

      // this.contentLoading = true;
      // let params = {
      //   dtuCode: this.info.dtuCode,
      // };
      // this.$api.getData("deviceGateway/detail", params).then(res => {
      //   this.info.softwareVersion = res.data.softVer;
      //   this.info.hardwareVersion = res.data.hardVer;
      //   console.log(res.data);
      //   this.contentLoading = false;
      // }).catch(() => {
      //   this.contentLoading = false;
      // });
    },
    handleSet(spiType) {
      var sipValue = "";
      if (spiType === 0) {
        sipValue = this.info.serverAddress + "," + this.info.port;
      }
      if (spiType === 1) {
        sipValue = this.info.account + "," + this.info.password;
      }
      if (spiType === 2) {
        let callNos = "";
        if (this.info.callNo1 !== "") {
          callNos = this.info.callNo1;
        }
        if (this.info.callNo2 !== "") {
          if (callNos.length > 0) {
            callNos = callNos + ",";
          }
          callNos = callNos + this.info.callNo2;
        }
        if (this.info.callNo3 !== "") {
          if (callNos.length > 0) {
            callNos = callNos + ",";
          }
          callNos = callNos + this.info.callNo3;
        }
        sipValue = callNos;
      }
      if (spiType === 3) {
        sipValue = this.info.volume;
      }
      if (spiType === 4) {
        sipValue = this.info.inFilter ? 1 : 0;
      }
      let params = {
        "dtuCode": this.info.dtuCode,
        "nodeCode": this.info.nodeCode,
        "sipType": spiType,
        "sipValue": sipValue,
        "dutyRoom": this.info.isDutyRoom,
      };
      this.$confirm(this.$t("common.set") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.contentLoading = true;
        this.contentLoadingTxt = this.$t("device.setLoadingTxt");
        this.$api.getData("remote/setTE", params).then(res => {
          let data = res.data;
          if (data.status === 1) {
            this.$message.success(this.$t("device.writeSuccess"));
          } else {
            this.$message.error(this.$t("device.writeFail"));
          }
          this.contentLoading = false;
          this.contentLoadingTxt = "";
        }).catch(error => {
          this.$message.error(this.$t("device.writeFail"));
          this.contentLoading = false;
          this.contentLoadingTxt = "";
        });
      });
    },
    handleRead(spiType) {
      let params = {
        "dtuCode": this.info.dtuCode,
        "nodeCode": this.info.nodeCode,
        "sipType": spiType,
      };
      this.contentLoading = true;
      this.contentLoadingTxt = this.$t("device.readLoadingTxt");
      this.$api.getData("remote/setTD", params).then(res => {
        let data = res.data;
        let arr = data.data.split(",");
        if (data.status === 1) {
          if (spiType === 0) {
            this.info.serverAddress = arr[0];
            this.info.port = arr[1];
          }
          if (spiType === 1) {
            this.info.account = arr[0];
            this.info.password = arr[1];
          }
          if (spiType === 2) {
            if (arr.length >= 1) {
              this.info.callNo1 = arr[0];
            }
            if (arr.length >= 2) {
              this.info.callNo2 = arr[1];
            }
            if (arr.length >= 3) {
              this.info.callNo3 = arr[2];
            }
          }
          if (spiType === 3) {
            this.info.volume = arr[0] * 1;
          }
          if (spiType === 4) {
            this.info.inFilter = arr[0] === "1";
          }
          this.$message.success(this.$t("device.readSuccess"));
        } else {
          // let b = "30" * 1;
          // alert(typeof (b));
          // this.info.volume = 30;
          this.$message.error(this.$t("device.readFail"));
        }
        this.contentLoading = false;
        this.contentLoadingTxt = "";
      }).catch(error => {
        this.$message.error(this.$t("device.readFail"));
        this.contentLoading = false;
        this.contentLoadingTxt = "";
      });
    },
    onDialogClose() {
      this.submitLoading = false;
      this.dialogVisible = false;
      this.contentLoading = false;
      this.contentLoadingTxt = "";
      this.dtuCode = "";
      this.nodeCode = "";
      this.info = {
        dtuCode: "",
        nodeCode: "",
        softwareVersion: "",
        hardwareVersion: "",
        serverAddress: "",
        port: "",
        account: "",
        password: "",
        callNo1: "",
        callNo2: "",
        callNo3: "",
        isDutyRoom: false,
        volume: 0,
        inFilter: false,
      };
    },
    handleSetMG() {
      this.btnLoading = true;
      let params = {
        dtuCode: this.dtuCode,
        nodeCode: this.nodeCode,
      };
      this.$http.get("remote/setMG", params).then(res => {
        this.btnLoading = false;
        this.$message.success(this.$t("elevator.tip.operateSuccess"));
      }).catch((error) => {
        this.btnLoading = false;
        this.$message.error(this.$t("elevator.tip.operateError") + ", " + error.response.data.message);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
